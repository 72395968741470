import { getZaniaSocket } from "@/infra/sockets";
import { AgentSocketEvents, type ControlEditPayload } from "../types/events";
import { getSessionJWT } from "@/infra/stytch";
import { VendorRiskSectionEnum } from "../types/vendorAssessment";
import { AgentSessionType } from "@/modules/sessions/types";

export const useAgentSocket = () => {
	const emitControlEdit = async (
		fileUrl: string,
		control: any,
		controlId: string,
		agentId: string,
		stepId: string,
		editedField: string,
	) => {
		try {
			const socket = getZaniaSocket();
			const token = await getSessionJWT();

			socket.emit(
				AgentSocketEvents.CONTROL_EDIT,
				{
					file_url: fileUrl,
					control,
					...(control.section === VendorRiskSectionEnum.vendor_overview
						? { control_key: controlId }
						: { control_id: controlId }),
					agent_session_id: agentId,
					agent_session_step_id: stepId,
					token,
					field: editedField,
					task: control.section === VendorRiskSectionEnum.vendor_overview 
						? "multi_file_vendor_assessment"
						: control.type === AgentSessionType.SOC2_GAP_TYPE1
						? "soc2_type1"
						: control.type === AgentSessionType.SOC2_GAP_TYPE2
						? "soc2_type2"
						: control.type === AgentSessionType.SOC2_GAP
						? "soc2_type2_audit"
						: control.type === AgentSessionType.DORA_GAP
						? "dora_assessment"
						: "nist_csf_2_risk_assessment",
				},
				(error: Error) => {
					if (error) {
						console.error("Socket emit error:", error);
					} else {
						console.log("Control edit emitted successfully");
					}
				},
			);
		} catch (error) {
			console.error("Error in emitControlEdit:", error);
			throw error;
		}
	};

	return { emitControlEdit };
};
