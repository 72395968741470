import { getZaniaSocket } from "@/infra/sockets";
import { getAgentData, getAgentStateActions } from "@/modules/agent/states";
import {
	AGENT_TYPES,
	GapAssessmentTypes,
	MultiVendorAssessmentTypes,
	ReviewResponseData,
	RiskAssessmentTypes,
} from "@/modules/agent/types";
import { ControlUpdatePayload } from "@/modules/agent/types/events";
import { QuestionnaireStructure } from "@/modules/agent/types/questionnaire";
import { processFileForDoraAudit } from "@/modules/agent/use-cases/dora.use-case";
import {
	processFileForReview,
	processManualMapping,
} from "@/modules/agent/use-cases/quesitionnaire.use-case";
import { processFileForRiskReview } from "@/modules/agent/use-cases/risk.use-case";
import { processFileForSOC2Type2Audit } from "@/modules/agent/use-cases/soc2.use-case";
import { processFileForVendorAssessmentResponse } from "@/modules/agent/use-cases/vendor.use-case";
import { getOrderedKeys } from "@/modules/agent/utils/get-order-keys";
import { getRenderType } from "@/modules/agent/utils/get-render-type";
import { getFieldTitle } from "@/modules/agent/utils/getFieldTitle";
import { updateAgentSessionStep } from "@/modules/sessions/requests";
import { AgentSessionStepType } from "@/modules/sessions/types";
import { useLoggedInMember } from "@/shared/hooks/use-login-member";
import useSocket from "@/shared/hooks/use-socket";
import { useCallback, useEffect } from "react";

export interface QAStructureCompletedResponse {
	agent_session_id: string;
	success: boolean;
	qa_structure: QuestionnaireStructure[];
}

export interface QAReviewResponseCompletedResponse {
	uid: string;
	success: boolean;
	agent_session_id: string;
	qa_filler: {
		filled_questionnaire_url: string;
	};
}

export interface RiskAssessmentComplete {
	agent_session_id: string;
	agent_session_step_id: string;
	success: boolean;
	risk_assessment_response: {
		file_url: string;
		file_type: string;
	};
}

export interface DoraAssessmentComplete {
	uid: string;
	success: boolean;
	dora_assessment_response: {
		file_url: string;
		file_type: string;
	};
}

export interface RagAuditComplete {
	agent_session_id: string;
	agent_session_step_id: string;
	success: boolean;
	rag_output: {
		file_url: string;
		file_type: string;
	};
	task: string;
}

const isIdInUrl = (id: string) => {
	const idInUrl = new URL(window.location.href).toString().includes(id);
	return idInUrl;
};

export const useAppSocket = () => {
	const { addListener, removeListener, isConnected } = useSocket("main", () =>
		getZaniaSocket(),
	);

	const loggedInMember = useLoggedInMember();
	const onQAStructureComplete = useCallback(
		async ({
			success,
			qa_structure,
			agent_session_id,
		}: QAStructureCompletedResponse) => {
			if (!success || !isIdInUrl(agent_session_id)) return;
			await processManualMapping({
				agent_session_id,
				structureData: qa_structure,
			});
		},
		[],
	);

	const onQAReviewResponseComplete = useCallback(
		async ({
			success,
			qa_filler,
			agent_session_id,
		}: QAReviewResponseCompletedResponse) => {
			if (!success || !qa_filler || !isIdInUrl(agent_session_id)) return;
			await processFileForReview(
				qa_filler.filled_questionnaire_url,
				agent_session_id,
			);
		},
		[],
	);

	const onRagAuditComplete = useCallback(
		async ({
			success,
			rag_output,
			agent_session_id,
			task,
		}: RagAuditComplete) => {
			if (!success || !rag_output || !isIdInUrl(agent_session_id)) return;

			switch (task) {
				case "soc2_type2_audit":
				case "soc2_type2":
				case "soc2_type1":
					await processFileForSOC2Type2Audit(
						rag_output.file_url,
						agent_session_id,
					);
					break;
				case "dora_assessment":
					await processFileForDoraAudit(rag_output.file_url, agent_session_id);
					break;
				case "nist_csf_2_risk_assessment":
					await processFileForRiskReview(rag_output.file_url, agent_session_id);
					break;

				case "multi_file_vendor_assessment":
					await processFileForVendorAssessmentResponse(
						rag_output.file_url,
						agent_session_id,
					);
					break;
			}
		},
		[],
	);
	const onControlUpdated = useCallback(
		async (payload: ControlUpdatePayload) => {
			const { file_url, agent_session_id, control, control_id, user_id } =
				payload;

			if (user_id === loggedInMember?.member_id) return;
			console.log("control updated");

			if (!isIdInUrl(agent_session_id)) return;

			// Get agent data for either NIST or SOC2
			const nistData = getAgentData<
				AGENT_TYPES.RISK_ASSESSMENT,
				RiskAssessmentTypes.NIST_CSF_2
			>(agent_session_id);
			const soc2Data = getAgentData<
				AGENT_TYPES.GAP_ASSESSMENT,
				GapAssessmentTypes.SOC2
			>(agent_session_id);
			const vendorData = getAgentData<
				AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
				MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
			>(agent_session_id);

			const agentData = nistData || soc2Data || vendorData;

			if (!agentData) return;

			const { stepData } = agentData;
			const editStepData = stepData?.find(
				(step) => step.type === AgentSessionStepType.EDIT_RESPONSE,
			);

			if (!editStepData) return;

			const updatedStep = {
				...editStepData,
				data: {
					url: file_url,
				},
			};

			const { updateAgentStepData } = getAgentStateActions();
			const updatedSteps = stepData.map((step) =>
				step.id === updatedStep.id ? updatedStep : step,
			);

			updateAgentStepData(agent_session_id, updatedSteps);

			const orderedKeys = getOrderedKeys(agentData.agentType, control.version);

			const transformedControl = orderedKeys.map((key) => ({
				type: getRenderType(key, control.version),
				value: control[key],
				key: key,
				title: getFieldTitle(key, control.version),
				shouldRender: true,
			}));

			// Get the Map entries
			const controlArrays = Array.from(
				agentData?.mainData.reviewResponseData?.entries() ?? new Map(),
			);

			// Find the entry containing our control
			const existingControlEntry = controlArrays.find(([key, fields]) =>
				fields.some(
					(field: ReviewResponseData<keyof typeof control>) =>
						field.key === "id" && field.value === control.id,
				),
			);
			const existingControl = existingControlEntry?.[1];

			if (existingControlEntry) {
				const [mapKey, existingFields] = existingControlEntry;
				const updatedFields = existingFields.map(
					(field: ReviewResponseData<keyof typeof control>) => {
						const matchingTransformed = transformedControl.find(
							(t) => t.key === field.key,
						);
						if (matchingTransformed) {
							return {
								...field,
								value: matchingTransformed.value,
								type: matchingTransformed.type,
							};
						}
						return field;
					},
				);

				// Create a new Map while preserving the same reference if data hasn't changed
				const newReviewResponseData = new Map(
					agentData?.mainData.reviewResponseData?.entries() ??
						new Map().entries(),
				);

				const existingValue = newReviewResponseData.get(mapKey);

				// Only update if the data has actually changed
				if (JSON.stringify(existingValue) !== JSON.stringify(updatedFields)) {
					newReviewResponseData.set(mapKey, updatedFields);

					const { updateAgentData } = getAgentStateActions();

					if (agentData.agentType === AGENT_TYPES.RISK_ASSESSMENT) {
						updateAgentData<
							AGENT_TYPES.RISK_ASSESSMENT,
							RiskAssessmentTypes.NIST_CSF_2
						>(agent_session_id, {
							...agentData,
							mainData: {
								...agentData.mainData,
								reviewResponseData: newReviewResponseData,
								selectedId: agentData.mainData.selectedId,
								lastUpdate: {
									timestamp: Date.now(),
									isRemoteUpdate: true,
								},
							},
						});
					} else if (agentData.agentType === AGENT_TYPES.GAP_ASSESSMENT) {
						updateAgentData<
							AGENT_TYPES.GAP_ASSESSMENT,
							GapAssessmentTypes.SOC2
						>(agent_session_id, {
							...agentData,
							mainData: {
								...agentData.mainData,
								reviewResponseData: newReviewResponseData,
								selectedId: agentData.mainData.selectedId,
								lastUpdate: {
									timestamp: Date.now(),
									isRemoteUpdate: true,
								},
							},
						});
					}
				}
			}
		},
		[],
	);

	useEffect(() => {
		addListener<QAStructureCompletedResponse>(
			"task:qa:structure:complete",
			(data) => void onQAStructureComplete(data),
		);
		addListener<QAReviewResponseCompletedResponse>(
			"task:qa:filler:complete",
			(data) => void onQAReviewResponseComplete(data),
		);
		addListener<RagAuditComplete>(
			"task:rag:complete",
			(data) => void onRagAuditComplete(data),
		);
		addListener<ControlUpdatePayload>(
			"shared:doc:updated",
			(data) => void onControlUpdated(data),
		);

		return () => {
			removeListener<QAStructureCompletedResponse>(
				"task:qa:structure:complete",
				(data) => void onQAStructureComplete(data),
			);
			removeListener<QAReviewResponseCompletedResponse>(
				"task:qa:filler:complete",
				(data) => void onQAReviewResponseComplete(data),
			);
			removeListener<ControlUpdatePayload>(
				"shared:doc:updated",
				(data) => void onControlUpdated(data),
			);
		};
	}, [
		addListener,
		onQAReviewResponseComplete,
		onQAStructureComplete,
		removeListener,
		onRagAuditComplete,
		onControlUpdated,
	]);

	return {
		isConnected,
	};
};
